import React from 'react';
import Layout from '../../components/Layout';
const team = () => {
  return (
    <Layout>
      <h1>team Us</h1>
      <p>
        This is team us text
      </p>
    </Layout>
  );
};

export default team;
